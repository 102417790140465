import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'lib/helpers';

import { useAppSelector } from 'redux/hooks';

import { EventBranding } from 'types/EventTypes';

import { Link } from 'components/Link';
import { Icon } from 'components/Icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChangeEditionModal } from './modals/ChangeEditionModal';

export const EditionsList: React.FC<{ lang: string | undefined }> = (props) => {
    const { lang } = props;
    const { event } = useAppSelector((state) => {
        return { event: state.store.event };
    });
    const { search } = useLocation();
    const cookies = new Cookies();
    const cartCookie = cookies.get('cart');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [newEditionUri, setNewEditionUri] = useState<string | undefined>(undefined);

    if (!event) return null;

    const linkClick = (e: React.MouseEvent<HTMLAnchorElement>, editionUri: string) => {
        e.preventDefault();
        if (!cartCookie) {
            navigate(`/${editionUri}${search}`);
        }
        if (editionUri === cartCookie?.edition) {
            const { edition, channel } = cartCookie;
            navigate(`/${edition}/${channel}${search}`);
        } else {
            setNewEditionUri(editionUri);
        }
    };

    const editions = event.editions;
    return (
        <>
            <EventWrapper branding={event.branding}>
                {event.branding.logo && (
                    <EventLogo>
                        <img src={event.branding.logo} alt={event.name} />
                    </EventLogo>
                )}
                <EventBox>
                    <EventName>{event.name}</EventName>
                    <EditionList>
                        {editions.map((edition) => {
                            return (
                                <EditionListItem key={edition.uri}>
                                    <Link to={edition.uri + search} onClick={(e) => linkClick(e, edition.uri)}>
                                        <div>
                                            <EditionName>
                                                {edition.name ? edition.name : t('editions_list.first_edition')}
                                            </EditionName>
                                            {edition.date.start && (
                                                <EditionDate>
                                                    {formatDate(edition.date.start, edition.date.hide_hours, lang)}
                                                </EditionDate>
                                            )}
                                        </div>
                                        <StyledIcon>
                                            <Icon name="arrow-right-s-line" />
                                        </StyledIcon>
                                    </Link>
                                </EditionListItem>
                            );
                        })}
                    </EditionList>
                </EventBox>
            </EventWrapper>
            <ChangeEditionModal
                event={event}
                newEditionUri={newEditionUri}
                unsetNewEditionUri={() => {
                    setNewEditionUri(undefined);
                }}
                search={search}
                onEditionsList
            />
        </>
    );
};

const EventWrapper = styled.div<{ branding: EventBranding }>`
    width: 100%;
    min-height: 100vh;
    padding: 1rem;
    background-color: ${(props) => props.branding.background.color || props.theme.colors.light};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${(props) =>
        props.branding.background.image &&
        css`
            background-image: url(${props.branding.background.image});
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: ${props.branding.background.position.x};
            ${props.branding.background.position.y};
            background-size: ${props.branding.background.size};
        `}
`;

const EventLogo = styled.div`
    width: 100%;
    max-width: 45rem;
    padding: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 70%;
        max-width: 30rem;
    }
`;

const EventBox = styled.div`
    width: 100%;
    max-width: 45rem;
    background-color: #fff;
    box-shadow: ${(props) => props.theme.helpers.boxShadow(props.theme.colors.fontMedium)};
    border-radius: 0.5rem;
    padding: 2.5rem;
`;

const EventName = styled.h1`
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
`;

const EditionList = styled.ul``;
const EditionListItem = styled.li`
    padding: 0.25rem 0;

    &:not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.colors.lines};
    }

    a {
        padding: 0.75rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        color: inherit;
        transition: all 200ms ease;

        &:hover {
            padding-left: 0.75rem;
        }
    }
`;

const EditionName = styled.h3`
    font-size: 1.125rem;
    font-weight: 800;
`;

const EditionDate = styled.h5`
    font-size: 0.875rem;
    font-weight: 500;
    /* font-style: italic; */
    font-variation-settings: 'ital' 125;
    margin-top: 0.25rem;
    color: ${(props) => props.theme.colors.fontLight};
`;

const StyledIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
`;
